@import "swiper/css";
@import "swiper/css/navigation";
@import "swiper/css/pagination";

.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  border-radius: 50%;
  top: 50%;
  width: 24px;
  height: 24px;
  margin-top: -12px;
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  &.swiper-button-disabled {
    opacity: 0.35;
    cursor: auto;
    pointer-events: none;
  }
  &:after {
    font-family: swiper-icons;
    font-size: 12px;
    text-transform: none !important;
    letter-spacing: 0;
    text-transform: none;
    font-variant: initial;
    line-height: 1;
    color: white;
  }
}
.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  &:after {
    content: "prev";
  }
  left: 10px;
  right: auto;
}
.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  &:after {
    content: "next";
  }
  right: 10px;
  left: auto;
}

.swiper-pagination-bullet-active {
  background: black;
}

.swiper-pagination {
  bottom: 0 !important;
}

.swiper {
  padding: 14px 0 !important;
  height: 100%;
}

.swiper-slide {
  height: auto !important;
}

.swiper-content {
  height: 100%;
}

.swiper-slide-item {
  height: 100%;
  width: 100%;
  min-height: 300px;
  background-size: cover;
  background-position: center;
}
